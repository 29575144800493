<script setup>

</script>

<template>
  <div class="a">
    <p><i class="bi bi-megaphone-fill"></i>&nbsp;我註冊了 <a href= ‘https://retro.app/@yuanhau’>Retro App</a>!</p>
  </div>
</template>
<style scoped>

.a {
  font-size: 0.6em;
  color: yellow;
}
a {
  color: rgba(255, 255, 0, 0.94);
}
a:hover {
  color: rgba(255, 255, 0, 0.87);
}
</style>